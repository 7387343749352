<template>
  <v-card class="pa-4" flat>
    <MasterClass
      :dataSchoolYears="dataSchoolYears"
      :selectedSchoolYear="currentSchoolYear"
      :dataGrade="dataGrade"
      :modelDialog="isDialogMaster"
      :isEdited="isEdited"
      :dataEdit="dataEdit"
      @toggleDialog="toggleDialogMaster"
      @getClassList="getClassList"
    />

    <ModalConfirm
      :loading="loadingModalDelete"
      :close="() => (this.modalConfirm = false)"
      :isOpen="modalConfirm"
      :save="deleteClass"
    />

    <v-row v-if="$vuetify.breakpoint.smAndDown" align="center" class="mb-5">
      <v-col cols="12" sm="6" class="d-flex flex-row align-center">
        <v-select
          v-model="modelSchoolYear"
          :loading="loadingSchoolYear"
          :items="dataSchoolYears"
          :label="$t('class_list.school_year')"
          :item-text="item => `${item.start_year} / ${item.end_year}`"
          item-value="id"
          outlined
          hide-details
          dense
          class="select-200 mr-3"
          @change="getClassList"
        >
          <template #item="{ item }">
            <span> {{ item.start_year }} / {{ item.end_year }} </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="schoolYearInfo(item.status).color"
                  small
                  class="ml-1"
                  >{{ schoolYearInfo(item.status).name }}</v-icon
                >
              </template>
              <span>{{ schoolYearInfo(item.status).info }}</span>
            </v-tooltip>
          </template>
        </v-select>
        <div class="subtitle-2 font-weight-bold" style="min-width: 75px">
          {{ dataClasses.length }} {{ $t("academic.class") }}
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex justify-end">
        <v-speed-dial
          v-model="menuKelas"
          style="z-index: 3"
          direction="bottom"
          class="ml-4"
          right
          open-on-hover
          transition="slide-y-reverse-transition"
        >
          <template v-slot:activator>
            <v-btn v-model="menuKelas" depressed color="primary" dark>
              <v-icon v-if="menuKelas">mdi-close</v-icon>
              <v-icon v-else>mdi-menu-down-outline</v-icon>
            </v-btn>
          </template>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="dataClasses.length === 0"
                fab
                dark
                small
                color="green"
              >
                <download-excel
                  :data="dataClasses"
                  :fields="fields"
                  :name="$t('class_list.name_file')"
                >
                  <v-icon>mdi-cloud-download</v-icon>
                </download-excel>
              </v-btn>
            </template>
            <span>{{ $t("app.download") }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                small
                color="indigo"
                @click="toggleDialogMaster(false)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("class_list.master_class") }}</span>
          </v-tooltip>
        </v-speed-dial>
      </v-col>
    </v-row>

    <v-row v-else no-gutters align="center">
      <v-select
        v-model="modelSchoolYear"
        :items="dataSchoolYears"
        :loading="loadingSchoolYear"
        :label="$t('master_data.student.school_year')"
        :item-text="item => `${item.start_year} / ${item.end_year}`"
        item-value="id"
        class="select-200 d-inline-block mr-3"
        outlined
        hide-details
        dense
        @change="getClassList"
      >
        <template #item="{ item }">
          <span> {{ item.start_year }} / {{ item.end_year }} </span>
          <v-tooltip right>
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                :color="schoolYearInfo(item.status).color"
                small
                class="ml-1"
                >{{ schoolYearInfo(item.status).name }}</v-icon
              >
            </template>
            <span>{{ schoolYearInfo(item.status).info }}</span>
          </v-tooltip>
        </template>
      </v-select>
      <v-btn
        :disabled="dataClasses.length === 0"
        class="gradient-primary caption mr-2"
        dark
        dense
        depressed
      >
        <download-excel
          :data="dataClasses"
          :fields="fields"
          :name="$t('class_list.name_file')"
        >
          {{ $t("app.download") }}
          <v-icon class="ml-2">mdi-cloud-download</v-icon>
        </download-excel>
      </v-btn>
      <div class="subtitle-2 font-weight-bold" style="min-width: 75px">
        {{ dataClasses.length }} {{ $t("academic.class") }}
      </div>

      <v-spacer />

      <v-btn
        class="gradient-primary caption mr-2"
        dark
        dense
        depressed
        @click="toggleDialogMaster(false)"
        >{{ $t("class_list.master_class")
        }}<v-icon class="ml-2">mdi-plus-circle-outline</v-icon></v-btn
      >
    </v-row>

    <v-divider class="my-4" />

    <v-data-table
      :headers="tableHeaders"
      :items="dataClasses"
      :loading="loading"
      disable-pagination
      item-key="id"
      hide-default-footer
      class="elevation-0"
    >
      <template #item.major="{ item }">
        {{ item.major.name }}
      </template>
      <template #item.conselor_teachers="{ item }">
        <div v-if="item.conselor_teachers.length == 0">-</div>
        <div v-else v-for="data in item.conselor_teachers" :key="data.id">
          {{ data.name }}
        </div>
      </template>
      <template #item.nip_conselor="{ item }">
        <div v-if="item.conselor_teachers.length == 0">-</div>
        <div v-else v-for="data in item.conselor_teachers" :key="data.id">
          {{ data.nip }}
        </div>
      </template>
      <template #item.homeroom_teachers="{ item }">
        <div v-if="item.homeroom_teachers.length == 0">-</div>
        <div v-else v-for="data in item.homeroom_teachers" :key="data.id">
          {{ data.name }} - {{ data.nip }}
        </div>
      </template>
      <template #item.conselor_teachers="{ item }">
        <div v-if="item.homeroom_teachers.length == 0">-</div>
        <div v-else v-for="data in item.conselor_teachers" :key="data.id">
          {{ data.name }} - {{ data.nip }}
        </div>
      </template>
      <template #item.action="{ item }">
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              class="mx-2 primary"
              v-on="on"
              icon
              small
              dark
              @click="toggleDialogMaster(true, item)"
            >
              <v-icon size="20">mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("class_list.edit") }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-2 error"
              icon
              v-on="on"
              small
              dark
              @click="openDialogDelete(item)"
            >
              <v-icon size="20">mdi-delete-forever</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("class_list.delete") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <DialogForceDelete
      :className="forceDeleteName"
      :modal="confirmForceDelete"
      :bodyDelete="bodyDelete"
      :totalRelations="totalRelations"
      :dataRelations="dataRelations"
      isMaster
      @close="
        () => {
          modalConfirm = false;
          loadingModalDelete = false;
          confirmForceDelete = false;
          getClassList();
        }
      "
    />
  </v-card>
</template>

<script>
import {
  class_list,
  data_tiers,
  delete_class
} from "@/api/admin/academic/classList";
import { get_school_year_list } from "@/api/admin/schoolClass";
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: i18n.t("routes.class_list"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    MasterClass: () => import("./MasterClass"),
    ModalConfirm: () => import("@/components/ModalConfirm"),
    DialogForceDelete: () => import("./DialogForceDelete")
  },
  created() {
    this.getSchoolYear();
    this.getGrade();
  },
  data() {
    return {
      currentSchoolYear: 0,
      dataEdit: {},
      isEdited: false,
      loadingModalDelete: false,
      isDialogMaster: false,
      modelSchoolYear: 0,
      loadingSchoolYear: false,
      modalConfirm: false,
      tableHeaders: [
        {
          text: i18n.t("app.grade"),
          value: "grade",
          width: 100
        },
        {
          text: i18n.t("class_list.table.class"),
          align: "left",
          value: "name"
        },
        {
          text: i18n.t("class_list.table.name_homeroom_teacher"),
          value: "homeroom_teachers"
        },
        {
          text: i18n.t("class_list.table.name_conselor"),
          value: "conselor_teachers"
        },
        {
          text: i18n.t("master_data.student.majors"),
          value: "major"
        },
        {
          text: i18n.t("class_list.table.total_student"),
          value: "total_student",
          align: "center"
        },
        {
          text: i18n.t("class_list.table.action"),
          sortable: false,
          value: "action",
          align: "center",
          width: 125
        }
      ],
      loading: false,
      fields: {
        [i18n.t("class_list.table.class")]: "name",
        [`${i18n.t("app.nip")} ${i18n.t(
          "class_list.table.name_homeroom_teacher"
        )}`]: {
          field: "homeroom_teachers",
          callback: value => value.map(d => d.nip).join(" | ")
        },
        [i18n.t("class_list.table.name_homeroom_teacher")]: {
          field: "homeroom_teachers",
          callback: value => value.map(d => d.name).join(" | ")
        },
        [`${i18n.t("app.nip")} ${i18n.t("class_list.table.name_conselor")}`]: {
          field: "conselor_teachers",
          callback: value => value.map(d => d.nip).join(" | ")
        },
        [i18n.t("class_list.table.name_conselor")]: {
          field: "conselor_teachers",
          callback: value => value.map(d => d.name).join(" | ")
        },
        [i18n.t("master_data.student.majors")]: {
          field: "major",
          callback: value => value.name
        },
        [i18n.t("class_list.table.total_student")]: "total_student"
      },
      menuKelas: false,
      dataClasses: [],
      dataSchoolYears: [],
      dataGrade: [],
      idClass: "",
      confirmForceDelete: false,
      totalRelations: 0,
      bodyDelete: { id: 0 },
      forceDeleteName: "",
      dataRelations: null
    };
  },
  methods: {
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case "ACTIVE":
          icon = active;
          break;
        case "WILLCOME":
          icon = willCome;
          break;
      }
      return icon;
    },
    openDialogDelete(item) {
      this.idClass = item.id;
      this.forceDeleteName = item.name;
      this.modalConfirm = true;
    },
    deleteClass() {
      this.loadingModalDelete = true;
      delete_class({ id: this.idClass })
        .then(res => {
          if (res.data.code === 1) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("class_list.response.success_delete"),
              color: "success"
            });
            this.loadingModalDelete = false;
            this.modalConfirm = false;
            this.getClassList(this.modelSchoolYear);
          } else if (res.data.code === 2) {
            const totalRelations =
              Number(res.data.data.schedule) + Number(res.data.data.student);
            this.dataRelations = res.data.data;
            this.totalRelations = totalRelations;
            this.bodyDelete.id = this.idClass;
            this.confirmForceDelete = true;
          } else {
            this.loadingModalDelete = false;
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
        })
        .catch(err => {
          this.$store.commit("CALL_SNACKBAR", {
            msg: err,
            color: "error"
          });
          this.loadingModalDelete = false;
          this.modalConfirm = false;
        });
    },
    getSchoolYear() {
      this.loadingSchoolYear = true;
      return get_school_year_list(true)
        .then(res => {
          let r = res.data.data;
          let currentSchoolYear = r.find(d => d.status == "ACTIVE");
          if (currentSchoolYear) {
            this.modelSchoolYear = currentSchoolYear.id;
            this.currentSchoolYear = currentSchoolYear.id;
            this.getClassList(this.modelSchoolYear);
          }
          this.dataSchoolYears = r;
          this.loadingSchoolYear = false;
        })
        .catch(error => {
          console.error("getSchoolYear()\n", error);
          this.loadingSchoolYear = false;
        });
    },
    getGrade() {
      this.loading = true;
      return data_tiers()
        .then(res => {
          this.dataGrade = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getClassList(idClass) {
      this.loading = true;
      const body = {
        filter: {
          "classroom.school_year_master": [idClass ?? this.modelSchoolYear]
        }
      };
      return class_list(body)
        .then(res => {
          this.dataClasses = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toggleDialogMaster(isEdited, itemClass) {
      this.isEdited = isEdited ?? false;
      if (isEdited) this.dataEdit = itemClass;
      if (this.isDialogMaster) this.getClassList();
      this.isDialogMaster = !this.isDialogMaster;
    }
  }
};
</script>
